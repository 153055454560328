import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Podcast from '../../../components/sections/Podcast'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'

import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/abtin-sarabi/background.png'
import still from '../../../assets/images/abtin-sarabi/still.png'


import img1 from '../../../assets/images/abtin-sarabi/1.png' 
import { PinDropSharp } from '@material-ui/icons'

const slides = [
  {
    label: 'O',
    image: img1
  },
];

export default function Album(props) {

  return (
    <>
      <Header
        name='Abtin Sarabi'
        title='Parcelles 7'
        date='4 – 24 October 2021'
      />

      <Text>
        <Typography variant='subtitle1'
          paragraph
        >
The mad proliferation of the wind gives the trees strange outlines. The wet thread of our slumber ruptures in the night. The hand plunges into the beginning of the fire. The burning entrails of the fields suddenly appear at dawn. Here man is alone. In this loneliness, the shadow of sugar cane flows into eternity.
        </Typography>
        <Typography variant='subtitle1'
        >
Director: Abtin Sarabi. With: Shabaaz Mystik.
        </Typography>
        <Typography variant='subtitle1'
          paragraph
        >
Production: Abtin Sarabi, Esther Creative Pictures, Dominique Olier (Krysalide Diffusion).
International relations: Ghasideh Golmakani. 2020, Senegal, Iran, France.
        </Typography>
      </Text>

      <Film 
        url="620149004?h=e4f33a17df"
        title="Abtin Sarabi, Parcelles 7 (2020) 28 min."
        titleShort="Abtin Sarabi - Parcelles 7"
      />

      <Essay 
        title="Caitlin Vandertop"
        subtitle="Ghosts of the Plantation"
       url="/articles/caitlin-vandertop-ghosts-of-the-plantation"
      >
      <Typography
        paragraph variant='subtitle1'
      >
Viewed as an energy-commodity, sugar can be seen to mediate the effect of energy on narrative time – what might be termed narrative energetics (Macdonald 2013) – in plantation fiction. As a commodity, sugar is a calorific fuel yet it also ‘naturalizes necessity’, in Niblett’s terms, ‘shaping bodies, tastes, habits, and even emotional geographies (sugar “highs” and “lows”)’ (Niblett 2015, 268). The historical process of cultivating the taste for sugar, as is shown in Sidney Mintz’s seminal study, Sweetness and Power (1985), altered the tempo of modern life by establishing disciplinary productivity in the cane fields and determining the hours of factory time in industrial Europe, forcing working-class women to substitute farmed foods for the short-term bursts of energy provided by the sugary cup of tea.
      </Typography>
      </Essay>

      <Soundtrack 
        title="Sugar Regime and the Gothic"
        subtitle="Caitlin Vandertop in conversation with Felice Moramarco"
        caption=""
        image={still}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/caitlin-vandertop-in-conversation-with-felice-moramarco"
      />

      <Gallery 
        slides={slides}
        title='Senegal, ESA Earth Observation, 2019'
      />
      
      <Footer />

    </>
  );
}
